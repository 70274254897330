import { template as template_f7ae82d547e64e5caca389c770e99233 } from "@ember/template-compiler";
const FKLabel = template_f7ae82d547e64e5caca389c770e99233(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
