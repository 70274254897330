import { template as template_b9df3c06c54a4588bae87d3a3cf56d81 } from "@ember/template-compiler";
const WelcomeHeader = template_b9df3c06c54a4588bae87d3a3cf56d81(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
