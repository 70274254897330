import { template as template_e999c4b63a664c0faf252d702310d3a7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e999c4b63a664c0faf252d702310d3a7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
